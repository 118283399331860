




















import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class CrmAppBar extends Vue {
  get showExtension(): boolean {
    return !!(this.$slots.extension || [])[0];
  }
}
