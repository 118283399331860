var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message",class:{
    'message--in': _vm.isIncoming,
    'message--out': _vm.isOutgoing,
    'message--opaque': _vm.isInactive,
    'message--flat': _vm.message.type.code === _vm.messageTypes.Sticker,
    'message--reply': _vm.replyMode,
    'message--large': _vm.large,
  }},[_c('v-card',{class:_vm.classes},[(_vm.message.reply)?_c('div',{staticClass:"chat-bubble-reply",style:({ 'background-color': 'white', 'border-left': ("4px solid " + (_vm.message.reply.messageConversationId
        ? _vm.$vuetify.theme.currentTheme.primary : _vm.$vuetify.theme.currentTheme.success)) })},[_c('h5',{staticClass:"pl-2 pt-1 pb-0"},[_vm._v(" "+_vm._s(_vm.message.reply.messageConversationId ? _vm.$t('crm.chatDialogMessage.label.you') : _vm.message.contactName)+" ")]),_c('CrmChatDialogMessageContent',{attrs:{"message":_vm.message.reply,"replyMode":_vm.replyMode,"templates":_vm.templates,"classes":'chat-bubble-content pa-2 pt-0 pb-1',"blockled":_vm.blocked},on:{"start-load-image":function($event){return _vm.onImageStartLoad()},"loaded-image":function($event){return _vm.onImageLoaded()},"delete-message":function($event){return _vm.onDeleteMessage()}}})],1):_vm._e(),_c('CrmChatDialogMessageContent',{attrs:{"message":_vm.message,"replyMode":_vm.replyMode,"enableActions":_vm.enableActions,"eagerLoadImage":_vm.eagerLoadImage,"templates":_vm.templates,"classes":'chat-bubble-content pa-1',"blockled":_vm.blocked},on:{"start-load-image":function($event){return _vm.onImageStartLoad()},"loaded-image":function($event){return _vm.onImageLoaded()},"delete-message":function($event){return _vm.onDeleteMessage()}}})],1),(!_vm.replyMode)?_c('div',{staticClass:"time text--secondary pl-2 pr-1"},[_vm._v(" "+_vm._s(_vm.$d(_vm.message.date, 'shortTime'))+" ")]):_vm._e(),(_vm.message.errorCode !== -1 && _vm.isTemporaryInError && !_vm.isMediaMessage)?_c('v-btn',{attrs:{"icon":"","plain":"","ripple":false},on:{"click":_vm.onClickRetryBtn},model:{value:(_vm.btnRetry),callback:function ($$v) {_vm.btnRetry=$$v},expression:"btnRetry"}},[_c('v-icon',{attrs:{"title":_vm.retryMessage}},[_vm._v(_vm._s(_vm.retryIcon))])],1):_vm._e(),(!_vm.replyMode && _vm.showStatusIcon)?_c('v-icon',{staticClass:"mr-2",class:{ 'icon-spin': _vm.statusMessageAnimated },attrs:{"color":_vm.statusMessageIconColor,"small":"","title":_vm.statusMessage}},[_vm._v(_vm._s(_vm.statusMessageIcon))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }